var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-center align-center",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex justify-end",staticStyle:{"width":"90%"}},[(_vm.enableProfilesVcad)?_c('filter-profile-dialog',{staticClass:"pa-3",attrs:{"fileList":_vm.fileListFiltered},scopedSlots:_vm._u([{key:"filterDialogActivator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"vcad-btn",attrs:{"small":"","outlined":"","elevation":"2","max-width":"12em","color":"primary"}},on),[_c('v-icon',[_vm._v("mdi-filter")]),_vm._v(" Filter profiles ")],1)]}}],null,false,1564427350)}):_vm._e()],1),(_vm.headers)?_c('v-data-table',{staticClass:"elevation-1 vcad-table",staticStyle:{"width":"90%"},attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.checkFilterVar ? _vm.fileListFiltered : _vm.rows,"hide-default-footer":"","search":_vm.search,"height":"700","item-key":"slotKey","loading":_vm.isLoading,"show-expand":"","custom-filter":_vm.customFilter,"items-per-page":-1},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"primary justify-space-between align-center d-flex lighten-2 vcad-table-top pa-3"},[_c('div',[_c('h3',{staticClass:"ml-1 white--text"},[_vm._v("Vcad slots")])]),_c('div',{staticClass:"d-flex align-center"},[_c('file-list-filter',{attrs:{"items":_vm.rows},on:{"filterApply":_vm.checkFilter},model:{value:(_vm.fileListFiltered),callback:function ($$v) {_vm.fileListFiltered=$$v},expression:"fileListFiltered"}}),_c('v-text-field',{staticStyle:{"width":"400px"},attrs:{"append-icon":"mdi-magnify","label":"Search by file name or file SLOT ID","hide-details":"","clearable":"","solo":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.dateEndConv",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.formattedEndDate))])]}},{key:"item.statusObj",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-flex align-center mx-auto"},[_c('span',[_vm._v(_vm._s(item.statusObj.txt))]),_c('v-icon',{staticClass:"mx-2",attrs:{"color":item.statusObj.color}},[_vm._v(_vm._s(item.statusObj.ico))])],1)]}},{key:"item.error",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"}},on),[_vm._v(_vm._s(item.error))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.error))])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"vcad-btn",attrs:{"outlined":"","small":"","max-width":"12em","color":"primary"},on:{"click":function($event){return _vm.goToFolder(item)}}},[_c('v-icon',[_vm._v("mdi-folder")]),_vm._v(" GO TO FOLDER ")],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-5",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-0 vcad-tables",attrs:{"headers":_vm.expansionTableHeaders,"items":item.groupedList,"item-key":"id","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","rounded":"","offset-x":"","close-on-click":"","close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.setViews(item)}}},'v-btn',attrs,false),on),[_vm._v(" Actions ")])]}}],null,true)},[_c('slot-actions',{staticClass:"flex-column pa-2",attrs:{"slotItem":item,"slotStatus":item.status},scopedSlots:_vm._u([{key:"btn-actions",fn:function(){return undefined},proxy:true}],null,true)})],1)]}}],null,true)})],1)]}}],null,true)}):_vm._e(),_c('vcad-dialog',{attrs:{"title":"Clear slot","contentProps":{ fileName: _vm.currentItem.name }},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var contentData = ref.contentData;
return [_c('p',{staticClass:"text-center"},[_vm._v(" You are about to clear the selected slot from the current loaded model : "),_c('i',[_c('b',[_vm._v(_vm._s(contentData.fileName))])])]),_c('p',{staticClass:"text-center"},[_vm._v(" Previously generated reports may not work correctly. Are you sure you wish to proceed? ")])]}},{key:"actions",fn:function(){return [_c('div',{staticClass:"d-inline-flex justify-end",staticStyle:{"width":"100%","height":"100%"}},[_c('v-btn',{staticClass:"vcad-btn",attrs:{"outlined":"","small":"","color":""},on:{"click":function($event){_vm.ShowDeleteDialog = false}}},[_vm._v("CLOSE")]),_c('v-btn',{staticClass:"vcad-btn",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.clearSlot(_vm.currentItem)}}},[_vm._v("CONFIRM")])],1)]},proxy:true}]),model:{value:(_vm.ShowDeleteDialog),callback:function ($$v) {_vm.ShowDeleteDialog=$$v},expression:"ShowDeleteDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }