<template>
  <v-tooltip top :disabled="status.statusId != 3" color="grey darken-4">
    <template v-slot:activator="{ on }">
      <div
        id="status-wrapper"
        class="d-inline-flex justify-space-between align-center"
        v-on="on"
      >
        <span
          ><v-icon :color="status.color">{{ status.ico }}</v-icon></span
        >
        <span id="status-label" class="font-weight-bold ml-1">{{
          status.txt
        }}</span>
        <v-spacer></v-spacer>
        <v-divider vertical class="mx-1"></v-divider>
        <v-menu
          style=""
          bottom
          rounded
          offset-x
          close-on-click
          close-on-content-click
          v-model="menuModel"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" :disabled="actionDisable" @click="setFile">
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <slot-actions
            class="flex-column pa-2 vcadItemStatusButtons"
            :slotItem="item.vcadItem"
            :slotStatus="status.statusId"
            v-if="status.statusId == 2 || status.statusId == 3"
            @selected-config="handleConfig"
          >
            <template v-slot:btn-actions>
              <!-- UPD SLOT -->
              <slot-version-changer
                v-if="status.statusId == 2"
                :slotGuid="slotChangerInfo.slotGuid"
                :fileId="slotChangerInfo.fileId"
                :fileType="slotChangerInfo.fileType"
                :projectId="slotChangerInfo.projectId"
              ></slot-version-changer>
            </template>
          </slot-actions>
          <div
            :style="
              !$vuetify.theme.dark
                ? 'background-color: white'
                : 'background-color: black'
            "
            class="justify-center align-center flex-column pa-2 d-flex"
            v-if="status.statusId == -1"
          >
            <!-- PREVIEW -->
            <v-btn
              outlined
              small
              width="100%"
              color="primary"
              class="vcad-btn"
              @click="
                setShowPreview({ show: true, mode: 0 });
                closeMenu();
              "
              >View</v-btn
            >
            <!-- IMPORT -->
            <vcad-import-dialog
              v-model="importDialog"
              :importFn="vcadFileImport"
              :fileName="item.fileName"
              :disableActivator="importingFile"
              :importingFile="importingFile"
              :filter="item.filterId ? item.filterId : null"
              :fileType="item.fileType"
              @selected-config="handleConfig"
            ></vcad-import-dialog>

            <!-- VIEWS LIST -->
            <vcad-views-list-dialog
              v-if="
                item.fileType == 'rvt' ||
                item.fileType == 'dwg' ||
                item.fileType == 'dgn'
              "
            ></vcad-views-list-dialog>
          </div>
        </v-menu>
      </div>
    </template>
    <span>More info in the "Additional info" section.</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getFileStatus, vcadFileStatusDict } from "../utils/VCadItemStatusUtil";
import VcadImportDialog from "./VcadImportDialog";
import SlotVersionChanger from "./SlotVersionChanger";
import VcadViewsListDialog from "./VcadViewsListDialog";
import SlotActions from "../components/SlotActions.vue";

export default {
  props: {
    item: {
      required: true,
      // default: null,
    },
    /**
     * Determines if the actions should be disabled
     */
    disabledActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Used by SlotVersionChanger
     */
    relativeFileId: {
      type: String,
      required: true,
    },
    relativeFileType: {
      type: String,
      required: true,
    },
    relativeProjectId: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: false,
    },
  },
  components: {
    "vcad-import-dialog": VcadImportDialog,
    "slot-version-changer": SlotVersionChanger,
    "vcad-views-list-dialog": VcadViewsListDialog,
    "slot-actions": SlotActions,
  },
  data() {
    return {
      statusDict: {},
      removeSlotDialog: false,
      resetStatusDialog: false,
      importDialog: false,
      importingFile: false,
      menuModel: false,
      viewsListDialog: false,
      filterApply: null,
      filterDialog: false,
      allowedTypeCheck: false,
      conversionType: null,
    };
  },
  watch: {
    status: {
      deep: true,
      handler(status) {
        if (status.statusId == 0) {
          this.importingFile = false;
        }
        if (status.statusId == 1) {
          this.importingFile = true;
        }
      },
    },
  },
  computed: {
    ...mapGetters([
      "vcadFileList",
      "userProfile",
      "supportedVcadFileTypes",
      "vcadUserInfo",
      "axiosSettings",
    ]),
    getConfigurationUserVcad() {
      try {
        return JSON.parse(this.vcadUserInfo);
      } catch (error) {
        return null;
      }
    },
    enableProfilesVcad() {
      try {
        return this.getConfigurationUserVcad.profilesEnable;
      } catch (error) {
        return null;
      }
    },
    filterSet() {
      if (this.filterApply) {
        return true;
      } else {
        return false;
      }
    },
    status() {
      let vcadStatus = getFileStatus(
        this.vcadFileList,
        this.item,
        this.supportedVcadFileTypes
      );
      const stat = vcadFileStatusDict[vcadStatus];
      return stat;
    },
    viewsFileCondition() {
      return this.item.fileType == "rvt";
    },
    actionDisable() {
      return this.disabledActions || this.status.statusId == -2;
    },
    /**
     * Information required by the Slot Changer component.
     */
    slotChangerInfo() {
      let ret = {};
      const projectUID = this.relativeProjectId.split("/").pop();

      // Check if current file is an item or a version
      if (this.item.type == "versions") {
        ret.fileId = this.item.sourceItemIdForVersion;
        ret.fileType = this.item.versionType;
      } else {
        ret.fileId = this.item.itemId;
        ret.fileType = this.item.type;
      }

      ret.slotGuid = this.item.vcadItem.id;
      ret.projectId = projectUID;
      ret.currentVersionLabel = `V${this.item.revisionDisplayLabel} (${this.item.lastUpdateDate})`;

      return ret;
    },
  },
  methods: {
    ...mapActions([
      "setShowPreview",
      "setShowTemplates",
      "setVcadImport",
      "restartConversion",
      "deleteVcadFile",
      "setCurrentViews",
      "setVcadTemplateList",
      "setFileData",
      "setSlotStatus",
    ]),
    handleConfig(value) {
      this.conversionType = value.type;
    },
    onSupportedTypesLoad(supportedTypes) {
      this.allowedTypeCheck = supportedTypes.length > 0;
    },
    closeFilterDialog(value) {
      this.filterDialog = value;
    },
    /**
ù     * Import the file to Vcad.
     */
    vcadFileImport(filterId) {
      // If the file is being imported, then skip.

      if (this.importingFile) {
        return true;
      }

      // Close menu list
      this.closeMenu();

      this.importingFile = true;
      let email = this.userProfile.email;

      let info = {
        userId: this.userProfile.id,
        email: email,
        itemId: this.item.itemId,
        fileName: this.item.fileName,
        viewableId: this.item.viewableId,
        viewableGuid: this.item.viewableGuid,
        viewableName: this.item.viewableName,
        b360Type: this.item.type,
        b360Item: this.item,
      };

      let payload = {
        email: email,
        fileName: this.item.fileName,
        urn: this.item.derivativeUrn,
        viewableGuid: this.item.viewableGuid,
        viewableName: this.item.viewableName,
        info: info,
        filterId: filterId,
        convType: this.conversionType,
      };

      this.setVcadImport(payload)
        .then(() => {
          this.importingFile = false;
        })
        .catch(() => {
          this.importingFile = false;
          // console.log(err);
        });
    },
    /**
     * Clear vcad slot.
     */
    removeVcadFile() {
      this.deleteVcadFile({ guid: this.item.vcadItem.id })
        .then(() => {
          this.removeSlotDialog = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    /**
     * Reset file import status, keeping link to current slot.
     */
    resetVcadFileStatus() {
      this.restartConversion({
        slotGuid: this.item.vcadItem.id,
        filterId: this.filterApply?.profileId,
      }).finally(() => {
        this.resetStatusDialog = false;
      });

      // this.setSlotStatus({
      //   slotGuid: this.item.vcadItem.id,
      //   status: 0,
      // }).finally(() => {
      //   this.resetStatusDialog = false;
      // });
    },
    /**
     * Setup current views and open template dialog
     */
    async openTemplateSelector() {
      // Load and set current views
      await this.setCurrentViews(this.item);

      // Set template list
      await this.setVcadTemplateList(this.item.vcadItem.type);

      // Open template selector component
      this.setShowTemplates(true);
    },

    /**
     * Set current forge file for use by "preview" and "template".
     * The function also emits an "selectedStatus" event.
     * @returns {Promise} promis of the store action.
     */
    setFile() {
      return this.setFileData({ slot: 1, data: this.item }).then((res) => {
        this.$emit("selected-status");
        return res;
      });
    },
    /**
     * Close menu when performing actions
     */
    closeMenu() {
      this.menuModel = false;
    },
  },
};
</script>

<style lang="css" >
#status-label {
  font-size: 0.75rem;
}
#status-wrapper {
  border-radius: 4px;
  padding: 0 0 0 5px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  width: 100%;
}
</style>