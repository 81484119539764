var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":3}},[_c('multimodel-project-manager',{ref:"multimodelProjectManager",staticClass:"elevation-1",staticStyle:{"overflow":"auto"},attrs:{"activeFolderName":_vm.folderName,"mmFiles":_vm.folderFiles,"userFederations":_vm.userFederations,"folderItemId":_vm.folderItemId,"projectId":_vm.projectId},on:{"fedDeletedEmit":_vm.fedDeletedEmit}})],1),_c('v-col',{attrs:{"cols":9}},[_c('v-data-table',{staticClass:"vcad-table",staticStyle:{"overflow":"auto"},attrs:{"headers":_vm.headers,"items":_vm.filteredModelList,"item-key":"id","search":_vm.searchTable,"height":"70vh","fixed-header":"","show-select":"","selectable-key":"isSelectable","loading":_vm.loadingData,"footer-props":{
            'items-per-page-options': [-1, 50, 100, 200],
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"elevation-1",attrs:{"dense":"","color":"primary lighten-1"}},[_c('span',{staticClass:"white--text"},[_vm._v("Selected Files: "+_vm._s(_vm.selectedFiles.length)+" / "+_vm._s(_vm.modelList.filter(function (e) { return e.isSelectable; }).length))]),_c('v-divider',{staticClass:"mx-3",attrs:{"dark":"","vertical":""}}),_c('v-spacer'),(_vm.infoBoxLoad)?_c('v-alert',{staticClass:"mr-1 warning--text",staticStyle:{"margin-bottom":"0 !important","padding":"2px 15px","height":"28px !important"},attrs:{"dense":"","border":"left","type":"warning","colored-border":"","id":"infoAlert"}},[_c('div',{staticClass:"text-truncate",staticStyle:{"width":"100%"}},[_vm._v(" Please wait while data is loading ")])]):_vm._e(),(_vm.hasPendingChanges)?_c('v-alert',{staticClass:"mr-1 warning--text",staticStyle:{"margin-bottom":"0 !important","padding":"2px 15px","height":"28px !important"},attrs:{"dense":"","border":"left","type":"warning","colored-border":"","id":"saveAlert"}},[_c('div',{staticClass:"text-truncate",staticStyle:{"width":"100%"}},[_vm._v(" Remember to save ")])]):_vm._e(),_c('v-btn',{staticClass:"ml-1 vcad-btn",attrs:{"outlined":"","small":"","color":_vm.slotStatusIcon.color},on:{"click":function($event){_vm.showMMSlotList = true}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":"","color":_vm.slotStatusIcon.color},on:{"click":function($event){_vm.showMMSlotList = true}}},[_vm._v(_vm._s(_vm.slotStatusIcon.icon))]),_vm._v(" Slots status: "+_vm._s(_vm.slotStatusIcon.msg)+" ")],1),_c('v-btn',{staticClass:"vcad-btn mr-1",attrs:{"outlined":"","small":"","color":"primary","disabled":_vm.selectedFiles.length <= 0 || !_vm.hasPendingChanges},on:{"click":_vm.saveMultiModel}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v("save")],1),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"vcad-btn",attrs:{"outlined":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-filter")])],1)]}}])},[_c('v-card',[_c('v-card-title',[_vm._v(" Filter Options ")]),_c('v-divider'),_c('v-card-text',[_c('v-switch',{staticClass:"custom-switch",attrs:{"color":"secondary","label":"Show selected"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Show selected")])]},proxy:true}]),model:{value:(_vm.filterSelectedFiles),callback:function ($$v) {_vm.filterSelectedFiles=$$v},expression:"filterSelectedFiles"}}),_c('v-switch',{staticClass:"custom-switch",attrs:{"color":"secondary","label":"Filter by format"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Filter by format")])]},proxy:true}]),model:{value:(_vm.filterByFormat),callback:function ($$v) {_vm.filterByFormat=$$v},expression:"filterByFormat"}})],1)],1)],1),_c('v-divider',{staticClass:"mx-4",attrs:{"dark":"","vertical":""}}),_c('v-text-field',{staticClass:"shrink",attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","clearable":"","solo":"","dense":""},model:{value:(_vm.searchTable),callback:function ($$v) {_vm.searchTable=$$v},expression:"searchTable"}})],1)]},proxy:true},{key:"item.FileName",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2 test",attrs:{"x-small":""}},[_vm._v(" V"+_vm._s(item.fileVersion)+" ")]),(item.Imported)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-application-import")]):_vm._e(),_vm._v(" "+_vm._s(item.FileName)+" "),(item.isSelectable)?_c('span',[_c('v-tooltip',{attrs:{"bottom":"","color":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.statusImported.color}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.statusImported.ico)+" ")])]}}],null,true)},[_c('span',[_vm._v("Imported")])])],1):_vm._e()]}},{key:"item.viewList",fn:function(ref){
          var item = ref.item;
return [(
                item.vcadItem &&
                (item.fileExt == 'rvt' || item.fileExt == 'dwg')
              )?_c('v-select',{key:item.id,staticClass:"my-3",attrs:{"loading":item.IsLoadingViews,"disabled":item.IsLoadingViews,"label":"View","return-object":"","hide-details":"","item-text":"name","item-key":"guid","items":item.viewList,"outlined":"","dense":""},model:{value:(item.selectedView),callback:function ($$v) {_vm.$set(item, "selectedView", $$v)},expression:"item.selectedView"}}):_c('div',{staticClass:"my-4"},[_vm._v("No views available")])]}},{key:"item.info",fn:function(ref){
              var item = ref.item;
return [(item.IsLoadingViews)?_c('v-alert',{staticStyle:{"margin-bottom":"0 !important"},attrs:{"border":"left","color":"orange","dense":"","outlined":""}},[_c('span',[_vm._v("Please wait while data is loading...")]),_c('v-progress-linear',{attrs:{"indeterminate":"","color":"orange"}})],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [(_vm.getWarningMsg(item).length > 0)?_c('v-alert',_vm._g({staticStyle:{"margin":"0","display":"flex","justify-content":"center","align-items":"center"},attrs:{"text":"","dense":"","color":"warning"}},on),[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-alert")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getWarningMsg(item)))])])]}},{key:"item.convTypeLabel",fn:function(ref){
              var item = ref.item;
return [_c('span',[_c('strong',[_vm._v("Conversion Type:")]),_vm._v(" "+_vm._s(item.vcadItem ? _vm.getFileConfigLabelMap[item.vcadItem.type] : "Not specified")),_c('br'),_c('strong',[_vm._v("Format:")]),_vm._v(" "+_vm._s(item.vcadFileConfigLabel)+" ")])]}}],null,true),model:{value:(_vm.selectedFiles),callback:function ($$v) {_vm.selectedFiles=$$v},expression:"selectedFiles"}},[(_vm.selectedMMProject)?_c('template',{slot:"no-data"},[_c('div',{staticClass:"d-flex align-center justify-center"},[(_vm.modelList.length > 0)?_c('div',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-alert-outline")]),_vm._v(" Files not selected ")],1):_c('div',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-alert-outline")]),_vm._v(" This type of files are not supported yet. Try with "+_vm._s(_vm.supportedMMFiles.join(", "))+" ")],1)])]):_vm._e()],2),_c('vcad-dialog',{attrs:{"title":"Slots Status","dialogWidth":"50%"},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.showMMSlotList)?_c('multimodel-slot-list'):_vm._e()]},proxy:true}]),model:{value:(_vm.showMMSlotList),callback:function ($$v) {_vm.showMMSlotList=$$v},expression:"showMMSlotList"}})],1),_c('vcad-snackbar',{attrs:{"value":_vm.snackbarIsVisible,"text":_vm.errorConfirmDialog,"customTimeout":4000,"customColor":_vm.colorSnackbar},on:{"closeSnack":function($event){return _vm.closeSnack()}}}),_c('vcad-snackbar',{attrs:{"value":_vm.saveSnackBar,"text":'Changes successfully saved',"customTimeout":2000,"customColor":"primary"},on:{"closeSnack":function($event){_vm.saveSnackBar = false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }