<template>
  <vcad-dialog
    v-model="multiFileActionDialog"
    title="Federations"
    fullscreen
    @input="dialogClose"
    :tabsProps="{}"
    :key="multiFileActionDialog"
  >
    <template v-slot:dialogActivator="{ on }">
      <slot name="fedDialogActivator" v-bind:on="on"></slot>
    </template>
    <template v-slot:tabs> </template>
    <template v-slot:content>
      <v-container fluid style="height: calc(100vh - 84px)">
        <v-tabs v-model="activeTab" dense>
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <v-tab
            v-for="tab in tabs"
            :key="tab.tabKey"
            :disabled="tab.isDisabled"
            dense
            >{{ tab.tabName }}</v-tab
          >
          <!-- <v-tab dense key="multi-model">Multi Model</v-tab>
              <v-tab dense key="alignment" :disabled="!selectedMMProject">Alignment </v-tab> -->
        </v-tabs>
        <v-tabs-items v-model="activeTab">
          <v-tab-item key="multi-model">
            <vcad-multi-model
              @fedDeletedEmit="fedDeletedEmit"
              @loadingData="isLoadingSlots"
              @changes="onSelectionChange"
              @warning="warningHandler"
              :folderName="folderName"
              :folderFiles="fileList"
              :userFederations="userFederations"
              :projectId="projectId"
              :folderItemId="folderItemId"
            ></vcad-multi-model>
          </v-tab-item>
          <v-tab-item key="alignment">
            <model-alignment :show="loadViewer"></model-alignment>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </template>

    <!-- ACTIONS -->
    <!--<template v-slot:actions>
      <div
        class="d-inline-flex align-center px-4"
        style="width: 100%; height: 100%"
      >
        <v-alert type="warning" border="left" outlined text dense class="mt-3" v-if="warningList.length > 0">
          <template v-for="warning in warningList">
            <p :key="warning.type">
              <b>WARNING:</b> {{ warning.msg }}
            </p>
          </template>

        </v-alert>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          small
          color="primary"
          class="vcad-btn"
          @click="multiFileActionDialog = false"
          >CLOSE</v-btn
        >
      </div>
    </template>-->
  </vcad-dialog>
</template>

<script>
import VcadDialog from "../VcadDialog";
import VcadMultiModel from "./VcadMultiModel";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import ModelAlignment from "./ModelAlignment.vue";
// import MultimodelProjectManager from "./MMProjectManager/MultimodelProjectManager.vue";

export default {
  props: {
    userFederations: {
      type: Array,
    },
    folderFiles: {
      type: Array,
      // required: true,
    },
    folderName: {
      type: String,
      // required: true,
    },
    folderId: {
      type: String,
      required: true,
    },
  },
  components: {
    ModelAlignment,
    VcadDialog,
    VcadMultiModel,
  },
  computed: {
    ...mapGetters([
      "axiosSettings",
      "selectedFolder",
      "selectedMMProject",
      "selectedProject",
      "folderMMProjects",
    ]),
    folderItemId() {
      let parts = this.folderId.split("/");
      let folderItemId = parts[8];
      return folderItemId;
    },
    projectId() {
      let parts = this.folderId.split("/");
      let projectId = parts[6];
      return projectId;
    },
    buttonIsDisabled() {
      if (this.folderFiles && this.folderFiles.length == 0) {
        return true;
      }
      return false;
    },
    fileList() {
      if (this.folderFiles) {
        return this.folderFiles;
      } else {
        return this.tmpFileList;
      }
    },
    loadViewer() {
      let index = this.tabs.findIndex((val) => {
        if (val.tabKey == "alignment") {
          return true;
        }
        return false;
      });
      if (this.activeTab == index) {
        return true;
      }
      return false;
    },
    tabs() {
      const hasNoSlots = this.selectedMMProject?.slots.length === 0;
      let alignmentIsDisabled = !this.selectedMMProject;
      let tabList = [
        { tabKey: "multi-model", tabName: "Definition", isDisabled: false },
        {
          tabKey: "alignment",
          tabName: "Alignment",
          isDisabled:
            alignmentIsDisabled ||
            this.hasPendingChanges ||
            this.loadingSlots ||
            hasNoSlots,
        },
      ];
      return tabList;
    },
  },
  data() {
    return {
      multiFileActionDialog: false,
      warningList: [],
      activeTab: 0,
      filterApply: null,
      hasPendingChanges: false,
      loadingSlots: false,
    };
  },
  watch: {
    fileList(files) {
      this.setMMFiles(files);
    },
    multiFileActionDialog(dialogIsOpen) {
      if (!dialogIsOpen) {
        this.activeTab = 0;
      }
    },
  },

  methods: {
    ...mapActions(["setMMFiles", "setSelectedMMProject"]),
    dialogClose() {
      this.$emit("dialogClosed", true);
    },
    fedDeletedEmit() {
      this.multiFileActionDialog = false;
      this.$emit("dialogClosed", true);
    },
    isLoadingSlots(val) {
      this.loadingSlots = val;
    },
    onSelectionChange(val) {
      this.hasPendingChanges = val;
    },
    /**
     * Update the warninig message.
     * @param {String} msg Warning message.
     * @param {String} type Type of  warning. Possible values ['import-warning', 'delete-warning']
     */
    warningHandler(msg, type) {
      // Create filtered copy of current warnings
      let newWarningList = this.warningList.filter((warn) => warn.type != type);
      // If new warning add to list
      if (msg) {
        newWarningList.push({ type: type, msg: msg });
      }
      // Update warning list
      this.warningList = newWarningList;
    },

    /**
     * Get all views for a given urn
     * @param {Object} context Object that exposes the same set of methods/properties on the store instance
     * @param {String} itemUrn URN of the item to recover the views for
     */
    async getViewsFromUrn(itemUrn) {
      let apiSettings = JSON.parse(JSON.stringify(this.axiosSettings));
      apiSettings.params = {
        urn: itemUrn,
      };

      return axios
        .get("api/forge/manifestitems", apiSettings)
        .then((e) => {
          return e.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  overflow: auto !important;
}
</style>