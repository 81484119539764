<template>
  <div>
    <v-container fluid fill-height>
      <v-row>
        <v-col :cols="3">
          <multimodel-project-manager
            style="overflow: auto"
            :activeFolderName="folderName"
            class="elevation-1"
            ref="multimodelProjectManager"
            :mmFiles="folderFiles"
            :userFederations="userFederations"
            @fedDeletedEmit="fedDeletedEmit"
            :folderItemId="folderItemId"
            :projectId="projectId"
          ></multimodel-project-manager>
        </v-col>
        <v-col :cols="9">
          <v-data-table
            v-model="selectedFiles"
            :headers="headers"
            :items="filteredModelList"
            class="vcad-table"
            item-key="id"
            :search="searchTable"
            height="70vh"
            style="overflow: auto"
            fixed-header
            show-select
            selectable-key="isSelectable"
            :loading="loadingData"
            :footer-props="{
              'items-per-page-options': [-1, 50, 100, 200],
            }"
          >
            <!-- EMPTY TABLE -->
            <template slot="no-data" v-if="selectedMMProject">
              <div class="d-flex align-center justify-center">
                <div v-if="modelList.length > 0">
                  <v-icon class="mr-2">mdi-alert-outline</v-icon>
                  Files not selected
                </div>
                <div v-else>
                  <v-icon class="mr-2">mdi-alert-outline</v-icon>
                  This type of files are not supported yet. Try with
                  {{ supportedMMFiles.join(", ") }}
                </div>
              </div>
            </template>
            <!-- TABLE HEADER -->
            <template v-slot:top>
              <v-toolbar dense class="elevation-1" color="primary lighten-1">
                <span class="white--text"
                  >Selected Files: {{ selectedFiles.length }} /
                  {{
                    modelList.filter((e) => {
                      return e.isSelectable;
                    }).length
                  }}</span
                >
                <v-divider dark vertical class="mx-3"></v-divider>

                <!-- <v-divider dark vertical class="mx-2"></v-divider> -->

                <!-- <span class="ml-1 white--text" v-if="hasPendingChanges"
                  >Remember to save!</span
                > -->

                <v-spacer></v-spacer>
                <v-alert
                  v-if="infoBoxLoad"
                  dense
                  border="left"
                  type="warning"
                  class="mr-1 warning--text"
                  colored-border
                  style="
                    margin-bottom: 0 !important;
                    padding: 2px 15px;
                    height: 28px !important;
                  "
                  id="infoAlert"
                  ><div style="width: 100%" class="text-truncate">
                    Please wait while data is loading
                  </div>
                </v-alert>
                <v-alert
                  v-if="hasPendingChanges"
                  dense
                  border="left"
                  type="warning"
                  class="mr-1 warning--text"
                  colored-border
                  style="
                    margin-bottom: 0 !important;
                    padding: 2px 15px;
                    height: 28px !important;
                  "
                  id="saveAlert"
                  ><div style="width: 100%" class="text-truncate">
                    Remember to save
                  </div>
                </v-alert>
                <v-btn
                  outlined
                  small
                  @click="showMMSlotList = true"
                  class="ml-1 vcad-btn"
                  :color="slotStatusIcon.color"
                  ><v-icon
                    @click="showMMSlotList = true"
                    dark
                    :color="slotStatusIcon.color"
                    class="mr-2"
                    >{{ slotStatusIcon.icon }}</v-icon
                  >
                  Slots status: {{ slotStatusIcon.msg }}
                </v-btn>
                <!-- <vcad-dialog
                  v-model="showPreviewFlag"
                  title="Preview Csv"
                  dialogWidth="1500"
                > -->
                <!-- <template v-slot:dialogActivator="{ on }">
                    PREVIEW CSV
                    <v-btn
                      outlined
                      small
                      color="primary"
                      class="vcad-btn mr-1"
                      v-on="on"
                      :disabled="
                        selectedFiles == null ||
                        selectedFiles.length == 0 ||
                        infoBoxLoad
                      "
                      ><v-icon>mdi-file-eye</v-icon>Preview</v-btn
                    >
                  </template> -->
                <!-- <template v-slot:content>
                    <v-data-table
                      :headers="headersCsv"
                      height="600"
                      :items="computedSelectedPreview"
                      class="elevation-0 vcad-table"
                      item-key="id"
                      fixed-header
                      hide-default-footer
                    >
                    </v-data-table>
                  </template> -->
                <!-- ACTIONS -->
                <!-- <template v-slot:actions>
                    <div
                      class="d-inline-flex align-center px-4"
                      style="width: 100%; height: 100%"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        small
                        color=""
                        class="vcad-btn"
                        @click="showPreviewFlag = false"
                        >CLOSE</v-btn
                      >
                    </div>
                  </template>
                </vcad-dialog> -->

                <!-- DOWNLOAD INFO -->
                <!-- <v-btn
                  outlined
                  small
                  color="primary"
                  class="vcad-btn mr-1"
                  :disabled="selectedFiles.length <= 0 || infoBoxLoad"
                  @click="downloadCSVData"
                  ><v-icon>mdi-file-download</v-icon> Download csv</v-btn
                > -->
                <v-btn
                  outlined
                  small
                  color="primary"
                  :disabled="selectedFiles.length <= 0 || !hasPendingChanges"
                  class="vcad-btn mr-1"
                  @click="saveMultiModel"
                  ><v-icon>mdi-content-save</v-icon>save</v-btn
                >
                <v-spacer></v-spacer>
                <v-menu :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined class="vcad-btn" small v-bind="attrs" v-on="on" >
                      <v-icon dense>mdi-filter</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title> Filter Options </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-switch
                        class="custom-switch"
                        v-model="filterSelectedFiles"
                        color="secondary"
                        label="Show selected"
                      >
                        <template v-slot:label>
                          <span>Show selected</span>
                        </template>
                      </v-switch>

                      <v-switch
                        class="custom-switch"
                        v-model="filterByFormat"
                        color="secondary"
                        label="Filter by format"
                      >
                        <template v-slot:label>
                          <span>Filter by format</span>
                        </template>
                      </v-switch>
                    </v-card-text>
                  </v-card>
                </v-menu>
                <v-divider dark vertical class="mx-4"></v-divider>
                <v-text-field
                  v-model="searchTable"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                  clearable
                  solo
                  dense
                  class="shrink"
                ></v-text-field>
              </v-toolbar>
            </template>
            <!-- VCAD FILE NAME -->
            <template v-slot:[`item.FileName`]="{ item }">
              <v-chip class="ma-2 test" x-small>
                V{{ item.fileVersion }}
              </v-chip>
              <v-icon v-if="item.Imported" small>mdi-application-import</v-icon>
              {{ item.FileName }}
              <span v-if="item.isSelectable">
                <v-tooltip bottom color="">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-on="on"
                      v-bind="attrs"
                      :color="statusImported.color"
                    >
                      {{ statusImported.ico }}
                    </v-icon>
                  </template>
                  <span>Imported</span>
                </v-tooltip>
              </span>
            </template>
            <!-- VIEW LIST -->
            <template v-slot:[`item.viewList`]="{ item }">
              <v-select
                v-if="
                  item.vcadItem &&
                  (item.fileExt == 'rvt' || item.fileExt == 'dwg')
                "
                :loading="item.IsLoadingViews"
                :disabled="item.IsLoadingViews"
                label="View"
                :key="item.id"
                v-model="item.selectedView"
                return-object
                hide-details
                item-text="name"
                item-key="guid"
                :items="item.viewList"
                outlined
                class="my-3"
                dense
              >
              </v-select>
              <div v-else class="my-4">No views available</div>
            </template>
            <!-- VCAD STATUS -->
            <template v-slot:[`item.info`]="{ item }">
              <v-alert
                v-if="item.IsLoadingViews"
                style="margin-bottom: 0 !important"
                border="left"
                color="orange"
                dense
                outlined
                ><span>Please wait while data is loading...</span
                ><v-progress-linear
                  indeterminate
                  color="orange"
                ></v-progress-linear
              ></v-alert>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-alert
                    style="
                      margin: 0;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                    text
                    dense
                    color="warning"
                    v-if="getWarningMsg(item).length > 0"
                    v-on="on"
                  >
                    <v-icon color="warning">mdi-alert</v-icon>
                  </v-alert>
                </template>
                <span>{{ getWarningMsg(item) }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.convTypeLabel`]="{ item }">
              <span>
                <strong>Conversion Type:</strong>
                {{
                  item.vcadItem
                    ? getFileConfigLabelMap[item.vcadItem.type]
                    : "Not specified"
                }}<br />
                <strong>Format:</strong>
                {{ item.vcadFileConfigLabel }}
              </span>
            </template>
          </v-data-table>

          <!-- show slot list -->
          <vcad-dialog
            title="Slots Status"
            v-model="showMMSlotList"
            dialogWidth="50%"
          >
            <template v-slot:content>
              <multimodel-slot-list
                v-if="showMMSlotList"
              ></multimodel-slot-list>
            </template>
          </vcad-dialog>
        </v-col>
        <vcad-snackbar
          :value="snackbarIsVisible"
          :text="errorConfirmDialog"
          :customTimeout="4000"
          :customColor="colorSnackbar"
          @closeSnack="closeSnack()"
        ></vcad-snackbar>
        <vcad-snackbar
          :value="saveSnackBar"
          :text="'Changes successfully saved'"
          :customTimeout="2000"
          customColor="primary"
          @closeSnack="saveSnackBar = false"
        ></vcad-snackbar>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  ActiveFileStatus,
  getFileStatus,
  vcadFileStatusDict,
  b360ElementStatusComplete,
} from "../../utils/VCadItemStatusUtil";
import prettyBytes from "pretty-bytes";
import { downloadCsv } from "../../utils/CsvUtil";
import axios from "axios";
import VcadDialog from "../VcadDialog";
import VcadSnackbar from "../VcadSnackbar.vue";
import MultimodelSlotList from "./MultimodelSlotList.vue";
import MultimodelProjectManager from "./MMProjectManager/MultimodelProjectManager.vue";

export default {
  props: {
    folderName: {
      type: String,
      // required: true,
    },
    folderFiles: {
      type: Array,
    },
    userFederations: {
      type: Array,
    },
    folderItemId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  components: {
    VcadDialog,
    "vcad-snackbar": VcadSnackbar,
    MultimodelSlotList,
    MultimodelProjectManager,
  },
  data() {
    return {
      headers: [
        {
          text: "File Name",
          value: "FileName",
        },
        {
          text: "View List",
          value: "viewList",
        },
        {
          text: "Info",
          value: "info",
        },
        {
          text: "Conv. Type",
          value: "convTypeLabel",
        },
      ],
      headersCsv: [
        {
          text: "Guid",
          value: "Guid",
        },
        {
          text: "View",
          value: "View",
        },
        {
          text: "File Name",
          value: "FileName",
        },
        {
          text: "Name",
          value: "Name",
        },
      ],
      selectedFiles: [],
      originalFileSelection: [],
      modelList: [],
      showPreviewFlag: false,
      snackbarIsVisible: false,
      errorConfirmDialog: "",
      colorSnackbar: "primary",
      modelProjectSlots: {},
      showMMSlotList: false,
      saveSnackBar: false,
      loadingData: true,
      searchTable: "",
      selectionHasChanged: false,
      supportedMMFiles: ["ifc", "nwd", "nwc", "dwg", "rvt"],
      supportedExtractionTypes: [5, 11, 7],
      viewsAreLoading: true,
      infoBoxLoad: false,
      filterSelectedFiles: false,
      filterByFormat: false,
    };
  },
  computed: {
    ...mapGetters([
      "vcadFileListConfig",
      "vcadFileList",
      "supportedVcadFileTypes",
      "axiosSettings",
      "selectedMMProject",
      "mmExtCompatibilityGroup",
      "mmSlotsNotFound",
      "selectedFolder",
    ]),

    getFileConfigLabelMap() {
      let slotLabel = {};
      this.vcadFileList.forEach((slot) => {
        let filteredConfigs = this.vcadFileListConfig.find(
          (config) => config.type == slot.type
        );

        slotLabel[slot.type] = filteredConfigs.label;
      });
      return slotLabel;
    },
    filteredModelList() {
      if (this.filterSelectedFiles) {
        return this.modelList.filter((rowItem) =>
          this.selectedFiles.includes(rowItem)
        );
      }

      if (this.filterByFormat && this.selectedFiles.length > 0) {
        const selectedFormat = this.selectedFiles[0].vcadFileConfigLabel;
        return this.modelList.filter(
          (rowItem) => rowItem.vcadFileConfigLabel === selectedFormat
        );
      }

      return this.modelList;
    },
    tmpLoadedViews() {
      return this.selectedFiles.map((file) => {
        let view = file.selectedView;
        return view?.guid;
      });
      // return this.modelList.filter(file => file.selectedView != undefined).length
    },
    b360ElementStatusComplete() {
      return b360ElementStatusComplete;
    },
    hasPendingChanges() {
      return this.viewsHaveChanged || this.selectionHasChanged;
    },
    viewsHaveChanged() {
      if (!this.selectedMMProject || !this.selectedFiles) {
        return false;
      }
      //1 prendere i guid delle viste selezionate degli slot della federazione
      let federatedFilesViewGuid = this.selectedMMProject.slots
        .map((slot) => {
          let view = slot.selectedView;
          return view?.guid;
        })
        .sort();
      //2 Prendere i guid delle viste selezionate dei selected files

      let selectedFilesViewGuid = this.tmpLoadedViews;
      selectedFilesViewGuid.sort();
      // console.log("Check");
      //3 Check se sono cambiate a parità di files
      if (federatedFilesViewGuid.length !== selectedFilesViewGuid.length) {
        return true;
      }
      for (let i = 0; i < federatedFilesViewGuid.length; i++) {
        if (federatedFilesViewGuid[i] !== selectedFilesViewGuid[i]) {
          return true;
        }
      }
      return false;
    },
    slotStatusIcon() {
      let obj = { icon: "mdi-application-import", color: "green", msg: "OK" };
      if (this.mmSlotsNotFound.length > 0) {
        obj.icon = "mdi-alert";
        obj.color = "red";
        obj.msg = this.mmSlotsNotFound.length + " not found";
      }
      return obj;
    },
    computedSelectedPreview() {
      let filesToDownload = this.selectedFiles;
      filesToDownload.forEach((e) => {
        e.View = e.selectedView?.guid;
        e.Name = e.selectedView?.name;
      });
      filesToDownload.sort(
        (first, second) => 0 - (first.Order > second.Order ? -1 : 1)
      );
      return filesToDownload;
    },
    statusImported() {
      return vcadFileStatusDict[2];
    },
  },
  watch: {
    loadingData() {
      this.$emit("loadingData", this.loadingData);
    },
    hasPendingChanges(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.emitPendingChanges();
      }
    },
    selectedFiles: {
      handler(val) {
        let th = this;
        let group = -1;

        // Ensure any necessary checks or updates are performed
        th.checkSlotNotFound();

        // Extract schema ID from the selected file for reference
        const selectedFileSchemaId = val[0]?.vcadFileConfigSchema;

        // Iterate over each model in the model list
        th.modelList.forEach((e) => {
          // Determine the current status of the Vcad item
          const VcadItemStatus = getFileStatus(
            th.vcadFileList,
            e,
            th.supportedVcadFileTypes
          );

          // Find the configuration for the current Vcad item type
          const vcadFileConfig = th.vcadFileListConfig.find(
            (config) => config.type === e.vcadItem?.type
          );

          // Check if the item can federate or not
          const canFederate = vcadFileConfig
            ? vcadFileConfig.canFederate
            : false;

          // Determine if the file can be selected based on its status and extraction type
          let checkSelectable =
            ActiveFileStatus.includes(VcadItemStatus) && canFederate;

          // Further check if the file's schema matches the selected file's schema
          if (checkSelectable && vcadFileConfig && selectedFileSchemaId) {
            checkSelectable = e.vcadFileConfigSchema === selectedFileSchemaId;
          }

          // Set the selectable status for the file
          e.isSelectable = checkSelectable;

          // Additional check for group selection logic
          if (checkSelectable) {
            e.isSelectable =
              group == -1 ||
              (vcadFileConfig &&
                val.some((file) => file.schemaId === vcadFileConfig.schemaId));
          }
        });

        // Function to sort files by their GUID
        const sortFiles = (a, b) => (a.Guid > b.Guid ? 1 : -1);

        // Create a sorted copy of the selected files
        let valCopy = [...val].sort(sortFiles);

        // Sort the original file selection for comparison
        this.originalFileSelection.sort(sortFiles);

        // Determine if the file selection has changed
        this.selectionHasChanged =
          JSON.stringify(valCopy) !==
          JSON.stringify(this.originalFileSelection);
      },
      deep: true,
    },

    async selectedMMProject(mmProject) {
      if (mmProject != null) {
        this.modelProjectSlots = {};

        // this.multimodelProject.slots = [{guid:""}]

        if (mmProject) {
          mmProject.slots.forEach((m) => {
            this.modelProjectSlots[m.Guid] = m;
          });
        }
        this.modelList = [];
        this.modelList = await this.getFileList();
        this.loadingData = false;

        this.selectedFiles = this.modelList.filter((e) => {
          let sel = false;
          if (this.modelProjectSlots[e.Guid] != null) {
            sel = true;
          }

          return e.isSelectable && sel;
        });
        this.originalFileSelection = [...this.selectedFiles];
      }
      this.loadingData = false;
    },
    async folderFiles() {
      this.modelProjectSlots = {};

      // this.multimodelProject.slots = [{guid:""}]

      if (this.selectedMMProject) {
        this.selectedMMProject.slots.forEach((m) => {
          this.modelProjectSlots[m.Guid] = m;
        });
      }
      this.modelList = [];
      this.modelList = await this.getFileList();
      this.loadingData = false;

      this.selectedFiles = this.modelList.filter((e) => {
        let sel = false;
        if (this.modelProjectSlots[e.Guid] != null) {
          sel = true;
        }

        return e.isSelectable && sel;
      });
      this.originalFileSelection = [...this.selectedFiles];
    },
    /**
     * When importErrorMsg changes, emit warning if needed.
     */
    importErrorMsg(errorMsg) {
      this.emitWarning(errorMsg, "import-warning");
    },
  },
  methods: {
    ...mapActions([
      "setCurrentViews",
      "setVcadImport",
      "deleteVcadFile",
      "saveUpdateMMProject",
      "setIsModifiedMM",
      "setProjectSaveStatus",
      "setMMSlotsNotFound",
      "updSelectedMMPrjSlot",
    ]),

    fedDeletedEmit() {
      this.$emit("fedDeletedEmit", false);
    },
    /**
     * Emit a warning message to parent component.
     */
    emitWarning(msg, type) {
      this.$emit("warning", msg, type);
    },
    emitPendingChanges() {
      this.$emit("changes", this.hasPendingChanges);
    },
    closeSnack() {
      this.snackbarIsVisible = false;
    },
    getSize(val) {
      let ret = prettyBytes(val);
      if (ret == "0 B") {
        return "";
      }
      return ret;
    },
    checkSlotNotFound() {
      let slotNotFound = [];
      Object.keys(this.modelProjectSlots).forEach((slotId) => {
        let file = this.modelList.find((m) => m.Guid == slotId);
        if (file == null) slotNotFound.push(this.modelProjectSlots[slotId]);
      });
      this.setMMSlotsNotFound(slotNotFound);
    },
    /**
     * Download information for the selected files.
     */
    downloadCSVData() {
      const downloadOptions = {
        separatorChar: ";",
        propList: this.headersCsv.map((header) => header.value),
      };
      let filesToDownload = this.selectedFiles;
      filesToDownload.forEach((e) => {
        e.View = e.selectedView.guid;
        e.Name = e.selectedView.name;
      });
      let nameFile = this.folderName + "_multimodel";
      filesToDownload.sort(
        (first, second) => 0 - (first.Order > second.Order ? -1 : 1)
      );
      downloadCsv(filesToDownload, downloadOptions, nameFile);
    },
    /**
     * Get all views for a given urn
     * @param {Object} context Object that exposes the same set of methods/properties on the store instance
     * @param {String} itemUrn URN of the item to recover the views for
     */
    async getViewsFromUrn(itemUrn) {
      let apiSettings = JSON.parse(JSON.stringify(this.axiosSettings));
      apiSettings.params = {
        urn: itemUrn,
      };

      return axios
        .get("api/forge/manifestitems", apiSettings)
        .then((e) => {
          return e.data;
        })
        .catch((err) => {
          console.log(err);
          throw err;
        });
    },
    async getFileList() {
      const th = this;
      const federatedSlots = this.selectedMMProject.slots;
      let asyncCalls = [];

      // Parse vcadFileList and create a row dictionary
      const rowDictionary = this.vcadFileList.reduce((dict, item) => {
        item.slotKey = item.slotKey || item.id;
        item.statusObj = vcadFileStatusDict[item.status];

        const info = JSON.parse(item.info);
        item.versionDescr = info.b360Item
          ? "V " + info.b360Item.revisionDisplayLabel
          : null;
        item.typeDescr = info.b360Item ? info.b360Item.type : null;
        item.folderName = info.b360Item?.folderName;
        item.importUser = info.email;

        // Create or update row in dictionary
        const slotKey = item.slotKey;
        if (!dict[slotKey]) {
          dict[slotKey] = {
            name: info.fileName,
            label: item.label,
            dateEndConv: item.dateEndConv,
            formattedEndDate: item.formattedEndDate,
            statusObj: item.statusObj,
            error: item.error,
            slotKey: slotKey,
            groupedList: [],
            folderName: item.folderName,
            importUser: item.importUser,
            slotId: item.id,
            nbrItems: 0, // initialize count
          };
        }
        dict[slotKey].groupedList.push(item);
        dict[slotKey].nbrItems++; // Increment count for items in expansion
        return dict;
      }, {});
      // Filter rows by folderName and flatten grouped lists
      let mergedGroupedList = [];

      this.folderFiles.forEach((folderFile) => {
        const folderSlotKey = folderFile.derivativeUrn.split("_")[0];

        // Check if the slotKey from folderFile exists in the rowDictionary
        if (rowDictionary[folderSlotKey]) {
          // Merge the groupedList from the rowDictionary into the mergedGroupedList
          mergedGroupedList = mergedGroupedList.concat(
            rowDictionary[folderSlotKey].groupedList
          );
        }
      });

      let list = mergedGroupedList.map((file, index) => {
        const b360Item = JSON.parse(file.info).b360Item;
        const VcadItemStatus = getFileStatus(
          th.vcadFileList,
          b360Item,
          th.supportedVcadFileTypes
        );

        const vcadFileConfig = th.vcadFileListConfig.find(
          (config) => config.type === file?.type
        );
        const canFederate = vcadFileConfig ? vcadFileConfig.canFederate : false;

        const isReadyInAutodesk =
          this.b360ElementStatusComplete === b360Item.itemState.processState;
        const checkSelectable =
          ActiveFileStatus.includes(VcadItemStatus) &&
          canFederate &&
          isReadyInAutodesk;

        let object = {
          id: file.id,
          Guid: file.id,
          derivativeUrn: b360Item.derivativeUrn,
          FileName: file.name,
          fileExt: file.name
            .substring(file.name.lastIndexOf(".") + 1)
            .toLowerCase(),
          isSelectable: checkSelectable,
          viewList: undefined,
          selectedView: undefined,
          Order: checkSelectable ? index + 1 : 0,
          Imported: false,
          IsLoadingViews: checkSelectable,
          vcadFileConfigSchema: vcadFileConfig?.schemaId,
          canFederate,
          fileVersion: b360Item.version,
          vcadFileConfigLabel: vcadFileConfig?.label,
        };

        // Async load views and selected view
        if (checkSelectable) {
          this.viewsAreLoading = true;
          this.infoBoxLoad = true;
          asyncCalls.push(
            this.getViewsFromUrn(b360Item.derivativeUrn).then((views) => {
              object.IsLoadingViews = false;
              object.viewList = views;
              const federatedSlot = federatedSlots.find(
                (slot) => slot.Guid === file.id
              );
              object.selectedView = federatedSlot
                ? federatedSlot.selectedView
                : views[0];
            })
          );
        }
        return object;
      });

      // Handle async operations and final loading state
      Promise.all(asyncCalls)
        .finally(() => {
          this.viewsAreLoading = false;
          this.infoBoxLoad = false;
        })
        .catch((error) => {
          this.snackbarIsVisible = true;
          this.errorConfirmDialog = "Error in loading files.";
          this.colorSnackbar = "error";
          console.error("Error:", error);
        });

      // Sort list by FileName
      return list.sort((a, b) => {
        if (a.FileName === b.FileName) {
          return a.fileVersion - b.fileVersion;
        } else {
          return a.FileName.localeCompare(b.FileName);
        }
      });
    },

    async saveMultiModel() {
      if (this.selectedMMProject != null) {
        let newSlots = [];
        // this.selectedMMProject.slots = [];
        this.selectedFiles.forEach((f) => {
          newSlots.push(f);
          // this.selectedMMProject.slots.push(f);
        });
        await this.updSelectedMMPrjSlot(newSlots);
        // const projectId = this.selectedFolder.projectId;
        // const folderItemId = this.selectedFolder.itemId;
        let payload = {
          federationData: this.selectedMMProject,
          projectId: this.projectId,
          folderItemId: this.folderItemId,
        };
        this.saveUpdateMMProject(payload)
          .then(() => {
            this.saveSnackBar = true;
          })
          .catch((err) => {
            console.error(err);
            // TODO: Implement snack bar with error
          });
        //this.$emit("multimodel-project-changed", this.multimodelProject);
      }
    },
    /**
     * Get info message for folder file, regarding the use of the file in a federation.
     * @param {any} folderFile Folder file item.
     * @return Information message.
     */
    getWarningMsg(folderFile) {
      const vcadItem = folderFile.vcadItem;
      // Check if file is imported
      if (!vcadItem) {
        return "File is not imported.";
      }
      if (!folderFile.canFederate) {
        return "The file cannot be federated due to conversion type.";
      }
      return "";
    },
  },
};
</script>

<style lang="css" scoped>
.vcad-table {
  border: solid rgba(0, 0, 0, 0.12);
}
#table .v-data-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.input__label {
  color: white;
}
.test {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
</style>